import { getCookie, setCookie } from "@/libraries/VueMethods";
import { uniqueId } from "@/libraries/VueMethods";
import cloneDeep from "lodash/cloneDeep";
import clone from "lodash/clone";

/**
 * @property {[{
 *   id: Number,
 *   price: Number,
 *   quantity: Number,
 *   discount_price: Number,
 *   variety_id: Number,
 * }]} cartItems
 */
export default class cart {
  // Dont add app to properties, it causes recursion error
  constructor(app, initialCarts = [], mode = null) {
    this.$set = app.$set;
    const homeData = app.$store.getters["front/getHomeData"];
    if (mode == "server" || homeData.user.login) {
      this.mode = "server";
      this.cartItems = initialCarts;
    } else {
      this.mode = "cookie";
      this.cartItems = initialCarts;
    }
  }

  async addToCart(cartItem) {
    let flag = false;
    this.cartItems.forEach((item) => {
      if (item.id == cartItem.id) {
        item.quantity = cartItem.quantity;
        item.price = cartItem.price;
        item.discount_price = cartItem.discount_price;
        flag = true;
      }
    });
    if (!flag) {
      this.cartItems.push(cartItem);
    }
  }

  deleteCart(cartItem) {
    this.cartItems = this.cartItems.filter((_cartItem) => {
      return _cartItem.id != cartItem.id;
    });
  }

  /**
   * @return {[{
   *   vId: String,
   *   q: Number,
   *   price: Number,
   *   d_price: Number
   * }]}
   */
  static getVarietyIdsFromCookie() {
    let fromCookie = JSON.parse(getCookie("cart"));
    return fromCookie ? fromCookie : [];
  }

  addFakeChart(variety, quantity, product) {
    if (variety.quantity == 0) {
      throw new Error("NO_STOCK");
    }
    let component = product.component;
    product.component = null;
    variety.product = cloneDeep(product);
    product.component = component;

    let flag = false;
    this.cartItems.forEach((item) => {
      // isNan checks if cart item is fake
      if (isNaN(item.id) && item.variety.id == variety.id) {
        let newQuantity = item.quantity + quantity;
        if (newQuantity > variety.quantity) {
          throw new Error("NO_STOCK");
        }
        item.quantity = newQuantity;
        flag = true;
      }
    });
    if (!flag) {
      this.cartItems.push(cart.makeFakeCart(variety, quantity));
    }
  }

  /**
   * To save in cookie
   */
  static makeFakeCart(variety, quantity) {
    return {
      id: uniqueId(), // Random Id
      variety_id: variety.id,
      quantity: variety.quantity < quantity ? variety.quantity : quantity,
      price: variety.final_price.amount,
      discount_price: variety.final_price.discount_price,
      variety,
    };
  }

  setCartQuantity(cartItem, newQuantity) {
    this.cartItems = this.cartItems.map((_cartItem) => {
      if (cartItem.id == _cartItem.id) {
        _cartItem.quantity = newQuantity;
      }

      return _cartItem;
    });
  }

  getTotalSaving(excloudedCartIds = []) {
    return this.cartItems.reduce((sum, item) => {
      if (excloudedCartIds.includes(item.id)) {
        return sum;
      }
      sum +=
        item.discount_price != null ? item.discount_price * item.quantity : 0;
      return sum;
    }, 0);
  }

  getTotalPrice(excludedCartIds = []) {
    return this.cartItems.reduce((sum, item) => {
      if (excludedCartIds.includes(item.id)) {
        return sum;
      }
      sum += item.price * item.quantity;
      return sum;
    }, 0);
  }

  // Check if variety already exists
  hasVariety(varietyId) {
    varietyId =
      varietyId && typeof varietyId === "object" ? varietyId.id : varietyId;
    return this.cartItems.find((cartItem) => {
      return cartItem.variety_id == varietyId;
    });
  }

  updateCookieIfNeeded() {
    if (this.mode === "cookie" && this.cartItems) {
      setCookie(
        "cart",
        JSON.stringify(
          this.cartItems.map((cartItem) => {
            return {
              vId: cartItem.variety.id,
              q: cartItem.quantity,
              price: cartItem.price,
              d_price: cartItem.discount_price, // felan niaz nist mahze etminan
            };
          })
        ),
        365
      );
    }
  }

  // اون کویری استرینگی که قراره درخواست بزنیم و میسازه
  static getCartRequestQueryString() {
    let fromCookie = cart.getVarietyIdsFromCookie();
    let varietyIds = [];
    let prices = [];
    let quantities = [];
    fromCookie.forEach((item) => {
      varietyIds.push(item.vId);
      prices.push(item.price);
      quantities.push(item.q);
    });

    return {
      variety_ids: varietyIds.join(","),
      prices: prices.join(","),
      quantities: quantities.join(","),
    };
  }

  // بعد اینکه تنوع هارو از درخواست گرفتیم حالا پرش میکنیم
  static getInitialCartForCookieMode(cartRequest) {
    let varieties = cartRequest.varieties;
    let warnings = cartRequest.warnings;
    let fromCookie = cart.getVarietyIdsFromCookie();
    let cartItems = [];
    varieties.forEach((varietyFromRequest) => {
      let quantity = fromCookie.find((fC, index) => {
        return fC.vId == varietyFromRequest.id;
      });
      quantity = quantity.q;

      // اگر موجودی محصول از سبد خرید کمتر بود این متد هندل میکنه
      cartItems.push(cart.makeFakeCart(varietyFromRequest, quantity));
    });
    cart.showWarnings(warnings, cartItems, "cookie");

    return cartItems;
  }

  // نمایش وارنینگ ها به مشتری با نوتیفیکیشن
  static showWarnings(warnings, cartItems, mode) {
    // اگر مود سرور باشه باید با آیدی مطابقت بدیم
    //اگر مود کوکی باشه با ایندکس
    if (mode == "cookie") {
      warnings.forEach((warning) => {
        let cartItem = cartItems[warning.cart_id];
        if (cartItem) {
          // نمایش ارور به کاربر
          // بنابه دلایلی نمیشه از نووتیفای استفاده کرد ارور میده
          alert(cart.getWarningMessages(warning, cartItem));
        }
      });
    }
  }

  static getWarningMessages(warning, cartItem) {
    // کانفیگ پیام ها
    const messages = {
      diffLower: [
        " توجه: از تعداد محصول ",
        " در انبار ما و سبد خرید شما کم شد ",
      ],
      diffPrice: ["توجه: قیمت محصول ", "تومان افزایش یافت", "تومان کاهش یافت"],
      unavailable: ["محصول ", " ناموجود شده و دیگر در سبد خرید شما موجود نیست"],
    };
    switch (warning.type) {
      case "quantity":
        return (
          messages.diffLower[0] +
          cartItem.variety.product.title +
          messages.diffLower[1]
        );
      case "price":
        return (
          messages.diffPrice[0] +
          cartItem.variety.product.title +
          " " +
          Math.abs(warning.diff_price) +
          (warning.diff_price > 0
            ? messages.diffPrice[1]
            : messages.diffPrice[2])
        );
      case "unavailable":
        return (
          messages.unavailable[0] +
          cartItem.variety.product.title +
          messages.unavailable[1]
        );
    }
  }
}
